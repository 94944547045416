<template>
  <div section4-review @mousedown="onMouseDown" @mouseup="onMouseUp">
    <div class="area-top">
      <avatar :info="userInfo" dimension="64x64" default-img-type="fruit" userProfile />
      <p class="user-name">{{ userNickname }} 님</p>
      <p class="coach-name">{{ coachNickname }} 코치님</p>
      <div class="top-margin"></div>
      <color-button type="dark">
        <img src="/img/coaching/plus-thin.svg" alt="plus" />
      </color-button>
    </div>
    <hr />
    <div class="stars">
      <img src="/img/coaching/rating-star-gold.svg" />
      <img src="/img/coaching/rating-star-gold.svg" />
      <img src="/img/coaching/rating-star-gold.svg" />
      <img src="/img/coaching/rating-star-gold.svg" />
      <img src="/img/coaching/rating-star-gold.svg" />
    </div>
    <p class="headline">{{ headline }}</p>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  components: { Avatar, ColorButton },
  props: {
    reviewInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ri() {
      return /** @type{LessonReview} */ this.reviewInfo;
    },
    description() {
      return this.ri?.description ?? '';
    },
    userInfo() {
      return this.ri?.user ?? {};
    },
    userNickname() {
      return this.ri?.user?.nickname ?? '';
    },
    coachNickname() {
      return this.ri?.coachNickname ?? '이름없음';
    },
    rating() {
      return this.ri?.rating ?? 0;
    },
    headline() {
      return this.ri?.headline ?? '';
    },
  },
  methods: {
    onMouseDown(ev) {
      this.$px = ev.clientX;
      this.$py = ev.clientY;
    },
    onMouseUp(ev) {
      if (this.$px === ev.clientX && this.$py === ev.clientY) this.$emit('click', this.reviewInfo);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[section4-review] { .wh(450, 394);.bgc(#fff);.br(8);display: inline-block;.p(32);.min-w(450);
  .area-top {.flex;.flex-ai(center);
    [avatar] {.wh(40, 40);.br(8);.mr(16)}
    .user-name {.mr(12);.fs(16);.lh(24)}
    .coach-name {.fs(14);.lh(21);.c(#c4c4c4)}
    .coach-name:before {border-left: solid 1px #c4c4c4;content: '';.wh(1, 14);.mr(12)}
    .top-margin {flex-grow: 1;}
    [color-button] {.wh(32, 32);.p(0, 8);
      svg > * {fill: white;}
    }
  }
  > hr { .mt(24);.mb(24);border-bottom: solid 1px #eaeaea;}
  .headline {.fs(24);.lh(32);.bold;.mt(24);white-space: pre-line;}
  .description {.fs(16);.lh(24);.c(#444);.mt(16);white-space: pre-line;}
  @media (@tp-down) {
    .wh(287, 320);.p(16);.min-w(287);
    .area-top {
      [avatar] {.wh(32, 32)}
      .user-name {.fs(12);.lh(24);.mr(8)}
      .coach-name {.fs(12);.lh(21);}
      .coach-name:before {.mr(8)}
    }
    > hr {.mt(16);.mb(16);}
    .headline {.fs(16);.lh(21);.mt(16)}
    .description {.fs(14);.lh(21);.mt(16)}
    .stars img {.wh(14, 13.53)}
  }
}
</style>
