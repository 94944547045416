<template>
  <layout-landing section10>
    <div class="parallax-clip">
      <div class="fixed-parallax">
      </div>
    </div>
    <div class="content-flex">
      <div class="top-margin" />
      <div class="lottie-check">
        <Lottie src="/lottie/check.json" fallback="/img/header-nav/logo-fallback.png" autoplay loop />
      </div>
      <p>당신에게 딱 맞는 게임코치,<br />우리가 찾아드릴께요</p>
      <color-button @click="btnHandler" type="white">주저하지 마세요!</color-button>
    </div>
  </layout-landing>
</template>
<script>
import Lottie from '@shared/components/general/Lottie.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import LayoutLanding from '@/views/components/coaching/landing/LayoutLanding.vue';

export default {
  components: { LayoutLanding, Lottie, ColorButton },
  methods: {
    btnHandler() {
      this.$gtag.event('click_user_landing_cta_section10', {
        'event_category': 'user_landing',
        'event_label': 'click_user_landing_cta_section10',
      });
      this.$router?.push({ name: 'CoachMatching' });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[section10] {.bgc(#d40404);.rel;.h(900);
  .content-flex {
    .flex;.flex-jc(center);.flex-ai(center);text-align: center;flex-direction: column;.z(100);.abs;.lt(0, 0);.rb(0, 0);.wh(100%, 100%)
  }
  .top-margin {.h(48)}
  p {.c(#fff);.bold;.fs(45);.lh(64);.block;}
  .lottie-check {.block;.mb(24);
    [lottie] {.wh(200, 200);.m(auto)}
  }
  [color-button] {.h(48); .fs(16); .br(4);.mt(48);.pl(23);.pr(23)}
  .parallax-clip {.wh(100%, 100%);.abs;.lt(0, 0); clip: rect(0, auto, auto, 0);}
  //.fixed-parallax {position: fixed;.lt(50%, 50%);transform: translate(-50%, -50%);}
  .fixed-parallax {position: fixed;.wh(100%, 100%);.lt(0, 0);
    /* webpackIgnore: true */
    background-image: url('@{img}/coaching/bg-symbol-left.svg'), url('@{img}/coaching/bg-symbol-right.svg');
    background-position: top left, right bottom;
    background-repeat: no-repeat, no-repeat;
  }
  /* CSS specific to iOS devices */
  @supports (-webkit-overflow-scrolling: touch) {
    .parallax-clip {
      clip: rect(auto, auto, auto, auto);
    }
    .fixed-parallax {
      position: fixed;
      background-attachment: unset;
      -webkit-transform: translateZ(0); /* necessary for iOS! */
    }
  }
  @media (@tp-down) {
    .h(464);
    p { .fs(24);.lh(32);}
    .lottie-check {.mb(16)}
    .lottie-check [lottie] {.wh(120, 120);}
    [color-button] { .fs(14); .mt(24); .h(40);}
    .fixed-parallax {background-size: 50% 50%, 50% 50%;}
  }
}
</style>
