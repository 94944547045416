<template>
  <section ref="section" landing-coach-lesson-section>
    <h2>1,000명 이상을<br /> 심해에서 탈출시킨 노하우</h2>
    <div class="content">
      <div ref="left" class="left-holder">
        <div :class="{'first': slidePos === 0, 'second': slidePos === 1, 'third': slidePos === 2 }" class="slide-holder">
          <div :class="{active:slidePos === 0 }" class="slide-title " @click="slideTitleItemHandler(0)">
            <img src="/img/coaching/icon-percent.svg" />
            <h4>합리적인 가격
              <span class="underline" />
            </h4>
          </div>
          <div :class="{active:slidePos === 1 }" class="slide-title" @click="slideTitleItemHandler(1)">
            <img src="/img/coaching/icon-star.svg" />
            <h4>수준 높은 코치진
              <span class="underline" />
            </h4>
          </div>
          <div :class="{active:slidePos === 2 }" class="slide-title" @click="slideTitleItemHandler(2)">
            <img src="/img/coaching/icon-report.svg" />
            <h4>수업 진행 방식
              <span class="underline" />
            </h4>
          </div>
        </div>
      </div>
      <div class="right-holder">
        <div :class="{'first': slidePos === 0, 'second': slidePos === 1, 'third': slidePos === 2 }" class="slide-holder">
          <div class="slide first">
            <div class="slide-title-holder">
              <h4 class="title">경쟁사 대비 80% 낮은 가격</h4>
              <span class="sub-desc">1:1 코칭 기준</span>
            </div>
            <Lottie ref="lottie-first" :src="'/lottie/lesson-cost-graph.json'" autoplay class="lottie first" loop />
          </div>
          <div class="slide second">
            <div class="slide-title-holder">
              <h4 class="title">게임코치에서 검증하는 최고의 코치님들</h4>
              <p class="title-desc">내게 딱 맞는 코치님을 선택해서 코치 받으세요!</p>
            </div>
            <Lottie ref="lottie-second" :src="'/lottie/lesson-chat.json'" class="lottie second" />
          </div>
          <div class="slide third">
            <div class="slide-title-holder">
              <h4 class="title">실력을 탄탄하게 해줄 풀코스 강의</h4>
            </div>
            <Lottie ref="lottie-third" :src="'/lottie/lesson-process.json'" autoplay class="lottie third" loop />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Lottie from '@shared/components/general/Lottie.vue';
import smoothScroll from 'smoothscroll-polyfill';

export default {
  name: 'LandingCoachLessonSection',
  components: { Lottie },
  data: () => ({
    slidePos: 0,
    lottieList: [],
    intervalId: null,
    clicked: false,
  }),
  computed: {
    isMobile() {
      return this.matchedMediaDevice === 'M';
    },
  },
  methods: {
    slideTitleItemHandler(pos) {
      if (this.intervalId) clearInterval(this.intervalId);
      this.slidePos = pos;
      this.lottieList[pos]?.play();
    },
    showNextTitleItem() {
      this.slidePos = (this.slidePos + 1) % 3;
      this.lottieList[this.slidePos]?.play();

      if (this.isMobile) {
        const leftEl = this.$refs?.left;
        const left = this.slidePos === 0 ? 0 : this.slidePos === 1 ? 220 : 400;
        leftEl.scrollTo({ left, behavior: 'smooth' });
      }
    },
    playAnimation() {
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => this.showNextTitleItem(), 3250);
    },
  },
  mounted() {
    // smooth scroll 지원 안 하는 브라우저에서 polyfill 하여 지원 하도록 함.
    smoothScroll.polyfill();
    this.$nextTick(() => {
      this.lottieList = [this.$refs?.['lottie-first'], this.$refs?.['lottie-second'], this.$refs?.['lottie-third']];
      const el = this.$refs?.section;
      if (!el) return;
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
              this.playAnimation();
              setTimeout(() => {
                observer.unobserve(el);
              }, 300);
            }
          });
        },
      );
      observer.observe(el);
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[landing-coach-lesson-section] { .w(100%); .h(877); .pt(188); .bgc(#fff);
  > h2 {.bold; .fs(42); .lh(64); .c(000); text-align: center;
    > br {display: none;}
  }
  .content {.mt(100); .flex; .items-center; .justify-center;}
  .left-holder { .mr(80); .w(272);
    .slide-holder {.flex; flex-direction: column; gap: 60px; }
    .slide-title { .flex; .items-center; .pointer;
      > img {.w(48); .mr(8); opacity: 0; transition: opacity ease-in-out 0.1s;}
      > h4 {.fs(32); .lh(32); .bold; .c(#c4c4c4); .nowrap;
        .underline {visibility: hidden;}
      }
      &.active {
        > h4 {.lh(42); .c(#191919); .rel; .z(1);
          .underline { visibility: visible; .w(100%); .h(12); .bgc(#ffbbbf); position: absolute; bottom: 1px; left: 0px; .z(-1);}
        }
        > img {opacity: 1;}
      }
    }
  }

  .right-holder { .w(548); .h(337); .rel; overflow: hidden;
    .slide-holder { transition: top ease-in-out 0.4s; .abs; .l(0); .t(0); .r(0);
      &.first {.t(0); }
      &.second {.t(calc(337px * 1 * -1));}
      &.third {.t(calc(337px * 2 * -1));}

      .slide { .h(337); .flex; flex-direction: column;
        &.first {
          .slide-title-holder {.mb(0);}
        }
        &.second {
          .slide-title-holder {flex-direction: column;}
          .lottie {.w(548); .h(220); }
        }
        &.third { .pt(55);
          .lottie {.w(425); .h(151);}
        }
        .slide-title-holder { .flex; .mb(40); .items-center; .justify-center;
          .title {.fs(24); .lh(36); .c(#d40404);}
          .title-desc {.mt(4); .fs(16); .c(#444);}
          .sub-desc { .fs(16); .lh(24); .c(#c4c4c4); .ml(6); align-self: end; }
        }
        .lottie { .w(358); .h(358); align-self: center;}
      }
    }
  }

  @media (@tp-down) {
    overflow: hidden;
    .pt(60);
    .h(525);
    .content {.mt(40);}
    > h2 {.fs(24); .lh(32);
      > br {display: unset;}
    }
    .content {flex-direction: column-reverse;}
    .left-holder { .mt(40); .rel; .w(100%); .ml(auto); .mr(auto); overflow-x: scroll; scroll-behavior: smooth;
      &::-webkit-scrollbar {display: none;}
      .slide-holder {.rel; flex-direction: row; gap: 42px; .w(fit-content); .pl(20); .pr(20); margin: 0 auto;
      }
      .slide-title {
        > h4 {.fs(24); .lh(32); .nowrap;}
        > img {.wh(40); .mr(4); }
        &.active {
          > h4 {.lh(32);
            .underline { .h(7); .bgc(#ffbbbf); position: absolute; bottom: 4px; .z(-1);}
          }
          > img {opacity: 1;}
        }
      }
    }
    .right-holder { .w(376); .h(221);
      .slide-holder {
        &.first {.t(0); }
        &.second {.t(calc(244px * 1 * -1));}
        &.third {.t(calc(244px * 2 * -1));}
        .slide { .flex; flex-direction: column; .m(0, auto);
          .h(244);
          &.first {
            .slide-title-holder {.mb(0);}
          }
          &.second {
            .slide-title-holder {flex-direction: column;}
            .lottie {.w(343); .h(172); }
          }
          &.third { .pt(0);
            .lottie {.w(325); .h(204);}
          }
          .slide-title-holder {.mb(16);
            .title {.fs(16); .lh(24);}
            .title-desc {.mt(4); .fs(12);}
            .sub-desc { .fs(12); .lh(18); .ml(4); }
          }
          .lottie { .w(244); .h(220); align-self: center;}
        }
      }
    }
  }

  @media (min-width: 0) and (max-width: 374px) {
    .right-holder {
      .slide-holder {
        .slide {
          &.second {
            .lottie {.w(250); .h(unset); }
          }
          &.third {
            .lottie {.w(250); .h(unset);}
          }
          .lottie { .w(250); .h(unset); .mt(20);}
        }
      }
    }
  }
}
</style>
