<template>
  <section landing-coach-find-section>
    <div class="section-inner">
      <div class="left-holder">
        <h2>그냥, 따라만 오세요!</h2>
        <p>맞춤형 1:1 코칭으로 강점은 극대화하고,<br /> 실력뿐 아니라 멘탈까지 캐리해 드리니까!</p>
        <BasicButton theme="red" class="btn" @click="linkHandler">내게 딱 맞는 코치 찾기</BasicButton>
      </div>
      <div class="right-holder">
        <div class="lottie-holder">
          <img src="/img/coaching/coach-landing-find-1.png" />
          <img src="/img/coaching/coach-landing-find-2.png" />
          <img src="/img/coaching/coach-landing-find-3.png" />
          <img src="/img/coaching/coach-landing-find-4.png" />
          <Lottie autoplay class="lottie" loop src="/lottie/smile.json" />
          <div class="shadow"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Lottie from '@shared/components/general/Lottie.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'LandingCoachFindSection',
  components: { BasicButton, Lottie },
  methods: {
    linkHandler() {
      this.$gtag.event('click_user_landing_cta_coach_find_section', {
        'event_category': 'user_landing',
        'event_label': 'click_cta_coach_find_section',
      });
      this.$router?.push({ name: 'CoachMatching' });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[landing-coach-find-section] { .flex; .w(100%); .h(692); .justify-center; .items-center;
  .section-inner { .flex;}
  .left-holder { .pt(44);
    > h2 {.c(#191919); .fs(45); .lh(64); .bold; .mb(8); }
    > p {.c(#444); .fs(20); .lh(30); .mb(48); font-weight: 500;}
    .btn { .h(48); .fs(16); .w(195); white-space: nowrap; .lh(100%); .br(8);
    }
    .mr(156);
  }
  .right-holder {
    .lottie-holder { .rel;
      .lottie {.wh(316); }
      .shadow {.l(0); .r(0); .m(0, auto); .b(6); .abs; .w(154); .h(24); background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);}
      img {
        &:nth-of-type(1) {.t(14); .l(32); .abs}
        &:nth-of-type(2) {.t(71); .l(13); .abs}
        &:nth-of-type(3) {.t(206); .l(265); .abs}
        &:nth-of-type(4) {.t(266); .l(254); .abs}
      }
    }
  }

  @media (@tp-down) {
    .h(509);
    align-items: unset; .pt(100); .rel;
    .section-inner { flex-direction: column; .items-center;}
    .left-holder { .pt(0); .flex; flex-direction: column; .items-center; .mr(0); .mb(16);
      > h2 {.fs(24); .mb(8); .lh(32); }
      > p {.fs(14); .mb(0); .lh(21); text-align: center; }
      .btn {.abs; .b(100); .m(0, auto); .l(0); .r(0); .h(40); .fs(14); .lh(14); }
    }

    .right-holder {
      .lottie-holder {
        .lottie { .wh(156);}
        .shadow {.w(76); .h(12);}
        img {
          &:nth-of-type(1) {.t(6); .l(18); .abs; .w(24); }
          &:nth-of-type(2) {.t(34); .l(6); .abs; .w(17);}
          &:nth-of-type(3) {.t(101); .l(130); .abs; .w(25);}
          &:nth-of-type(4) {.t(131); .l(125); .abs; .w(9);}
        }
      }
    }
  }
}

</style>
