<template>
  <section landing-media-seciton>
    <div class="parallax-clip">
      <div class="fixed-parallax">
        <img class="parallax-bg-left" src="/img/coaching/bg-symbol-black.svg" />
        <img class="parallax-bg-right" src="/img/coaching/bg-symbol-black.svg" />
      </div>
    </div>
    <div class="title-holder">
      <h2>“코 치 차 이”</h2>
      <p>게임코치온라인의 강의력을 직접 확인해보세요!</p>
    </div>
    <div class="media">
      <youtube :video-id="videoId" :player-vars="playerVars" ref="youtube" @playing="playing" @paused="paused" @ended="ended"></youtube>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LandingMediaSection',
  data() {
    return {
      timer: '',
      videoStartTime: '',
      videoId: 'wW4_HRtjv9s',
      playerVars: {
        autoplay: 0,
        start: 183,
      },
    };
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  mounted() {
    clearInterval(this.timer);
  },
  methods: {
    playing() {
      this.videoStartTime = performance.now();
      this.timer = setInterval(this.reportPlayTime, 15000);
      this.$gtag.event('play_user_landing_video', {
        'event_category': 'user_landing',
        'event_label': 'play_user_landing_video',
        'value': this.videoId,
      });
    },
    paused() {
      clearInterval(this.timer);
      this.$gtag.event('pause_user_landing_video', {
        'event_category': 'user_landing',
        'event_label': 'pause_user_landing_video',
        'value': this.videoId,
      });
    },
    ended() {
      clearInterval(this.timer);
      this.$gtag.event('end_user_landing_video', {
        'event_category': 'user_landing',
        'event_label': 'end_user_landing_video',
        'value': this.videoId,
      });
    },
    reportPlayTime() {
      const now = performance.now();
      const duration = (now - this.videoStartTime).toFixed(0);
      this.player.getCurrentTime().then(time => {
        if (duration < 31000) {
          this.$gtag.event('play_user_landing_video_10sec_more', {
            'event_category': 'user_landing',
            'event_label': 'play_user_landing_video_10sec',
            'value': `${this.videoId} currentTime: ${time}sec`,
          });
        } else if (duration < 61000) {
          this.$gtag.event('play_user_landing_video_30sec_more', {
            'event_category': 'user_landing',
            'event_label': 'play_user_landing_video_30sec_more',
            'value': `${this.videoId} currentTime: ${time}sec`,
          });
        } else if (duration > 61000) {
          this.$gtag.event('play_user_landing_video_60sec_more', {
            'event_category': 'user_landing',
            'event_label': 'play_user_landing_video_60sec_more',
            'value': `${this.videoId} currentTime: ${time}sec`,
          });
        } else if (duration > 121000) {
          this.$gtag.event('play_user_landing_video_120sec_more', {
            'event_category': 'user_landing',
            'event_label': 'play_user_landing_video_120sec_more',
            'value': `${this.videoId} currentTime: ${time}sec`,
          });
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[landing-media-seciton] {.bgc(#191919); .w(100%); .h(999); .flex; flex-direction: column; .items-center; .rel;
  .parallax-clip {.wh(100%, 100%);.abs;.lt(0, 0); clip: rect(0, auto, auto, 0);}
  .parallax-bg-left {.abs;left: 0;top: 0;.h(800); transform: scale(.5) translate(-70%);.o(.02)}
  .parallax-bg-right {.abs;right: 0;bottom: 0;.h(800); transform: scale(.5) translate(80%, 20%);.o(.02)}
  //.fixed-parallax {position: fixed;.lt(50%, 50%);transform: translate(-50%, -50%);}
  .fixed-parallax {position: fixed;.wh(100%, 100%);.lt(0, 0);}
  /* CSS specific to iOS devices */
  @supports (-webkit-overflow-scrolling: touch) {
    .parallax-clip {
      clip: rect(auto, auto, auto, auto);
    }
    .fixed-parallax {
      position: fixed;
      background-attachment: unset;
      -webkit-transform: translateZ(0); /* necessary for iOS! */
    }
  }

  .title-holder {.pt(188); .flex; .items-center; flex-direction: column;
    > h2 {.fs(45); .lh(64); .c(#fff);}
    > p {.fs(20); .lh(30); .c(#777);}
  }
  .media { .mt(64);.z(10);
    > iframe {border: solid 4px #444; .w(800); .h(450); }
  }

  @media (@tp-down) {
    .parallax-bg-left {
      transform: scale(.3) translate(-150%);.o(.02);
    }
    .parallax-bg-right {
      transform: scale(.3) translate(150%, 20%);.o(.02);
    }
    .h(490);

    .title-holder {.pt(100);
      > h2 {.fs(24); .lh(32);}
      > p {.fs(14); .lh(21);}
    }
    .media {.mt(40);
      > iframe {.w(335); .h(189);}
    }
  }

}

</style>
