<template>
  <layout-landing section6>
    <div class="lottie-confetti">
      <Lottie src="/lottie/ribbon.json" fallback="/img/header-nav/logo-fallback.png" autoplay loop />
    </div>
    <scroll-observer tag="h1" v-slot="{inScroll}" :delay-enter="500">
      <span class="fade" :class="{before:!inScroll}">대한민국 최고의 게임 학원이</span>
      <br /> <em class="fade" :class="{before: !inScroll}">당신을 위해 온라인으로!</em>
    </scroll-observer>
    <scroll-observer tag="p" v-slot="{inScroll}" :delay-enter="500">
      <span class="academy-name fade" :class="{before: !inScroll}">"게임코치아카데미"</span>
      <br />
      <span class="academy-location fade" :class="{before: !inScroll}">서울 홍대점, 부산 센텀점</span>
    </scroll-observer>
    <scroll-observer tag="div" v-slot="{inScroll}" :delay-enter="500">
      <ul class="fade" :class="{before: !inScroll}">
        <li>국내 최초 교육청 인허가</li>
        <li>7년연속 1위 명문 e스포츠 교육 전문 기관</li>
        <li>프로게이머 최다 배출로 검증된 교육과정</li>
      </ul>
    </scroll-observer>
    <img class="img-trophy" src="/img/coaching/trophy.png" alt="trophy" />
  </layout-landing>
</template>
<script>
import Lottie from 'shared/components/general/Lottie.vue';
import LayoutLanding from '@/views/components/coaching/landing/LayoutLanding.vue';
import ScrollObserver from '@/views/components/coaching/ScrollObserver.vue';

export default {
  components: { LayoutLanding, Lottie, ScrollObserver },
  computed: {
    fadeTransition() {
      return { name: 'fade', enter: 'enter', leave: 'leave' };
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[section6] {.bgc(#f6f6f6);text-align: center;.flex;flex-direction: column;.flex-ai(center);.flex-jc(center);.h(890);
  h1 {.c(@c-title-black);.mb(80);.fs(45);.lh(55);.z(10)}
  h1 em {.c(#d40404)}
  .lottie-confetti {.rel;.block;.wh(100%, 1)}
  .lottie-confetti [lottie] {.wh(500, 500);.m(auto);.abs;transform: translate(-50%, 0);.l(50%);.t(-150)}
  .academy-name {.fs(32);.lh(48);.bold;}
  .academy-location {.fs(16);.lh(24);.c(#444)}
  ul {.mt(32);.c(#444);.fs(20);.lh(30)}
  .img-trophy {.wh(80, 86);.mt(32);}

  .fade {
    transition-property: opacity, transform;
    -webkit-transition-property: opacity, transform;
    transition-duration: .8s;
    -webkit-transition-duration: .8s;
    display: inline-block;
  }
  .fade.before {
    opacity: 0;
    transform: translate(0, 15px);
    -webkit-transform: translate(0, 15px);
  }

  @media (@tp-down) {
    .h(531);
    h1 {.fs(24);.lh(32); .mb(40);}
    .lottie-confetti [lottie] {.wh(333, 333);}
    .academy-name {.fs(24);.lh(32)}
    .academy-location {.fs(12);.lh(18)}
    ul {.fs(14);.lh(21)}
    .img-trophy {.wh(61, 66)}
  }
}
</style>
