import { render, staticRenderFns } from "./LayoutLanding.vue?vue&type=template&id=5d0e1d7e&"
var script = {}
import style0 from "./LayoutLanding.vue?vue&type=style&index=0&id=5d0e1d7e&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports