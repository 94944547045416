<template>
  <modal modal-section4-review @close="$emit('close')">
    <section4-review :review-info="reviewInfo" />
  </modal>
</template>
<script>
import Modal from '@shared/components/common/Modal.vue';
import Section4Review from '@/views/components/coaching/landing/Section4Review.vue';

export default {
  components: { Section4Review, Modal },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    reviewInfo() {
      return this.options?.reviewInfo ?? {};
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[modal-section4-review] {
  [section4-review] {.p(0);.br(0);.min-w(100%);.w(unset);
    [color-button].dark {.hide;}
  }
}
</style>
