<template>
  <layout-landing section4>
    <scroll-observer tag="p" v-slot="{inScroll}" class="text-title">
      <span class="fade" :class="{before:!inScroll}">재수강률과 만족도로 증명 끝!</span>
    </scroll-observer>
    <table class="numbers">
      <thead>
        <tr>
          <th>누적 수업시간</th>
          <th>재수강률</th>
          <th>누적 후기</th>
          <th>수강생 만족도</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <scroll-observer v-slot="{inScroll}" tag="span" :delay-enter="numberBaseDelay">
              <span class="fade" :class="{before:!inScroll}">
                <auto-number-animator :value="inScroll ? 172800 : 0" tag="span" v-slot="{targetNumber}" :duration="300" class="number-em">
                  {{ commaDecimal(Math.round(targetNumber)) }}
                </auto-number-animator>
                <span>시간</span>
              </span>
            </scroll-observer>
          </td>
          <td>
            <scroll-observer tag="span" v-slot="{inScroll}" :delay-enter="numberBaseDelay + numberDelay">
              <span class="fade" :class="{before:!inScroll}">
                <auto-number-animator :value="inScroll ? 83.9 : 0" tag="span" v-slot="{targetNumber}" :duration="300" class="number-em">
                  {{ targetNumber.toFixed(1) }}
                </auto-number-animator>
                <span>%</span>
              </span>
            </scroll-observer>
          </td>
          <td>
            <scroll-observer tag="span" v-slot="{inScroll}" :delay-enter="numberBaseDelay + numberDelay * 2">
              <span class="fade" :class="{before:!inScroll}">
                <auto-number-animator :value="inScroll ? 12000 : 0" tag="span" v-slot="{targetNumber}" :duration="300" class="number-em">
                  {{ commaDecimal(Math.round(targetNumber)) }}
                </auto-number-animator>
                <span>개</span>
              </span>
            </scroll-observer>
          </td>
          <td>
            <scroll-observer tag="span" v-slot="{inScroll}" :delay-enter="numberBaseDelay + numberDelay * 3">
              <auto-number-animator :value="inScroll ? 4.9 : 0" tag="span" v-slot="{targetNumber}" :duration="300" class="number-em fade" :class="{before:!inScroll}">
                {{ targetNumber.toFixed(1) }}
              </auto-number-animator>
              <span class="number-em fraction fade" :class="{before:!inScroll}">5</span>
            </scroll-observer>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="numbers mobile-only first-table">
      <thead>
        <tr>
          <th>누적 수업시간</th>
          <th>재수강률</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <scroll-observer v-slot="{inScroll}" tag="span" :delay-enter="numberBaseDelay">
              <auto-number-animator :value="inScroll ? 172800 : 0" tag="span" v-slot="{targetNumber}" :duration="500" class="number-em fade" :class="{before:!inScroll}">
                {{ commaDecimal(Math.round(targetNumber)) }}
              </auto-number-animator>
              <span>시간</span>
            </scroll-observer>
          </td>
          <td>
            <scroll-observer tag="span" v-slot="{inScroll}" :delay-enter="numberBaseDelay">
              <auto-number-animator :value="inScroll ? 83.9 : 0" tag="span" v-slot="{targetNumber}" :duration="500" class="number-em fade" :class="{before:!inScroll}">
                {{ targetNumber.toFixed(1) }}
              </auto-number-animator>
              <span>%</span>
            </scroll-observer>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="numbers mobile-only">
      <thead>
        <tr>
          <th>누적 후기</th>
          <th>수강생 만족도</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <scroll-observer tag="span" v-slot="{inScroll}" :delay-enter="numberBaseDelay">
              <auto-number-animator :value="inScroll ? 12000 : 0" tag="span" v-slot="{targetNumber}" :duration="500" class="number-em fade" :class="{before:!inScroll}">
                {{ commaDecimal(Math.round(targetNumber)) }}
              </auto-number-animator>
              <span>개</span>
            </scroll-observer>
          </td>
          <td>
            <scroll-observer tag="span" v-slot="{inScroll}" :delay-enter="numberBaseDelay">
              <auto-number-animator :value="inScroll ? 4.9 : 0" tag="span" v-slot="{targetNumber}" :duration="500" class="number-em" :class="{before:!inScroll}">
                {{ targetNumber.toFixed(1) }}
              </auto-number-animator>
              <span class="number-em fraction">5</span>
            </scroll-observer>
          </td>
        </tr>
      </tbody>
    </table>
    <free-slider auto v-slot="{index}">
      <section4-review
        v-for="lessonReview in lessonReviews"
        :key="`${lessonReview.lessonReviewId}-${index}`"
        :review-info="lessonReview"
        @click="onReviewClick"
      />
    </free-slider>
  </layout-landing>
</template>

<script>
import { commaDecimal } from 'shared/utils/numberUtils';
import LayoutLanding from '@/views/components/coaching/landing/LayoutLanding.vue';
import AutoNumberAnimator from '@/views/components/coaching/AutoNumberAnimator.vue';
import ScrollObserver from '@/views/components/coaching/ScrollObserver.vue';
import Section4Review from '@/views/components/coaching/landing/Section4Review.vue';
import FreeSlider from '@/views/components/coaching/FreeSlider.vue';
import coachReviews from '@/constants/coach-reviews';
import ModalSection4Review from './ModalSection4Review.vue';

export default {
  data: () => ({
    numberBaseDelay: 500,
    numberDelay: 300,
  }),
  components: { FreeSlider, Section4Review, AutoNumberAnimator, LayoutLanding, ScrollObserver },
  computed: {
    lessonReviews() {
      return coachReviews;
    },
  },
  methods: {
    commaDecimal,
    onReviewClick(reviewInfo) {
      this.$gtag.event('click_user_landing_review_modal', {
        'event_category': 'user_landing',
        'event_label': 'click_user_landing_review_modal',
        'value': reviewInfo.lessonReviewId,
      });

      this.$modal(ModalSection4Review, { reviewInfo });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[section4] {.bgc(#f6f6f6);.flex;.flex-jc(center);.flex-ai(center);flex-direction: column;.h(1048);
  table.numbers {text-align: center;.chakra;.w(1000)}
  table.numbers th {.c(#444);.fs(16);.lh(24);.w(calc(100% / 4))}
  table {
    td + td { border-left: solid 1px #ddd;}
  }
  .text-title {text-align: center;.fs(45);.lh(64);.bold;.c(@c-title-black);.mb(62);}
  .number-em {.c(#d40404);.chakra;.fs(40);.bold;
    &.fraction:before {.c(#c4c4c4);font-weight: 500;.mr(5);
      content: '/';
    }
  }

  .fade {
    transition-property: opacity, transform;
    transition-duration: .8s;
    display: inline-block;
  }
  .fade.before {
    opacity: 0;
    transform: translate(0, 15px);
  }

  [free-slider] {.mt(80)}
  [free-slider] .content-wrap + .content-wrap {.ml(16);}
  [section4-review] + [section4-review] {.ml(16)}
  table.numbers.mobile-only {.hide}
  @media (@tp-down) {
    .h(662);
    .text-title {.fs(24);.lh(32);.pt(60);.mb(31);}
    table.numbers {.w(340)}
    table.numbers:not(.mobile-only) {.hide}
    table.numbers.mobile-only {display: table;}
    table.numbers td { .fs(12)}
    table.numbers th {.w(50%)}
    table {
      td + td { border-left: none;}
    }
    table td {.w(50%)}
    .first-table {.mb(23.5);}
    .first-table td:after { .wh(140, 1);.block;.m(auto);.mt(23.5);
      content: '';
      border-bottom: solid 1px #ddd;
    }
    table.numbers th { .fs(12);.lh(18);}
    .number-em {.fs(24);
      &.fraction:before {.fs(12)}
    }
    [free-slider] {.mt(31)}
  }
}
</style>
