<template>
  <div coach-home>
    <div class="container" :class="{ ready: isPageReady }">
      <client-only placeholder="1:1맞춤 온라인 게임강의 게임코치 온라인" tag="h1">
        <LandingMainSection />
      </client-only>
      <Section2 />
      <LandingCoachFindSection />
      <Section4 />
      <LandingMediaSection />
      <Section6 />
      <LandingCoachLessonSection />
      <Section10 />
    </div>
  </div>
</template>

<script>
import { getter } from '@shared/utils/storeUtils';
import coach from '@/mixins/coach';
import PointBuyModal from '@/views/components/coaching/PointBuyModal.vue';
import LandingCoachFindSection from '@/views/components/coaching/landing/LandingCoachFindSection.vue';
import LandingCoachLessonSection from '@/views/components/coaching/landing/LandingCoachLessonSection.vue';
import LandingMediaSection from '@/views/components/coaching/landing/LandingMediaSection.vue';
import LandingMainSection from '@/views/components/coaching/landing/LandingMainSection.vue';
import Section2 from '@/views/components/coaching/landing/Section2.vue';
import Section4 from '@/views/components/coaching/landing/Section4.vue';
import Section6 from '@/views/components/coaching/landing/Section6.vue';
import Section10 from '@/views/components/coaching/landing/Section10.vue';

export default {
  name: 'CoachHome',
  components: { Section2, Section4, Section6, Section10, LandingMainSection, LandingMediaSection, LandingCoachLessonSection, LandingCoachFindSection },
  mixins: [coach],
  data() {
    return {
      isPageReady: false,
    };
  },
  metaInfo() {
    return {
      title: this.$t('meta.coachLoL.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachLoL.title') },
        { vmid: 'description', content: this.$t('meta.coachLoL.description') },
        { vmid: 'keywords', content: this.$t('meta.coachLoL.keywords') },
        { vmid: 'url', content: this.$t('meta.coachLoL.url') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
      ],
    };
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    isCoach: getter('auth', 'isCoach'),
  },
  async mounted() {
    const { showPointModal, chatUrl } = this.$route.query;
    if (showPointModal === 'show') {
      // this.$modal(PointBuyModal);
      alert('포인트 충전이 불가합니다.');
    }
    if (chatUrl) {
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }

      this.$services.chat.openChatWithUrl(this, chatUrl);
      this.$router.push({ chatUrl: '' });
    }
    this.$gtag.event('land_user_landing', {
      event_category: 'user_landing',
      event_label: 'land_user_landing',
    });
    this.$nextTick(function () {
      if (this.isCoach) this.$router.replace('/coach-center');
      this.isPageReady = true;
    });
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[coach-home] { .rel; .bgc(#fff);
  > .container {
    opacity: 0;
  }
  > .ready {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }
}

</style>
