<template>
  <layout-landing section2 ref="container">
    <div class="title-area">
      <div class="lottie-emoji-area">
        <Lottie class="lottie-emoji" src="/lottie/difficult.json" fallback="/img/header-nav/logo-fallback.png" autoplay yoyo />
      </div>
      <scroll-observer tag="p" class="text-white first" :delay-enter="300">
        <span>롤이</span>
      </scroll-observer>
      <div class="carousel-box" @mouseenter="onCarouselMouseEnter" @mouseleave="onCarouselMouseLeave">
        <Lottie src="/lottie/landing-carousel.json" autoplay loop ref="carousel" />
      </div>
      <scroll-observer tag="p" class="text-white second" :delay-enter="300">
        <span>느껴진다면?</span>
      </scroll-observer>
    </div>
  </layout-landing>
</template>
<script>
import Lottie from 'shared/components/general/Lottie.vue';
import LayoutLanding from '@/views/components/coaching/landing/LayoutLanding.vue';
import ScrollObserver from '@/views/components/coaching/ScrollObserver.vue';

export default {
  components: { LayoutLanding, Lottie, ScrollObserver },
  methods: {
    onCarouselMouseEnter() {
      this.$refs.carousel.pause();
    },
    onCarouselMouseLeave() {
      this.$refs.carousel.play();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[section2] {.bgc(#d40404);.flex;.flex-ai(center);.flex-jc(center);.noto;
  .lottie-emoji-area {.wh(150, 150); .rel;
    [lottie] {.wh(150, 150)}
  }

  .lottie-emoji {.wh(100%, 100%)}
  .title-area {.flex;.flex-ai(center);
    .text-white {.fs(45);.lh(67);.c(#fff);.bold;.rel;.h(67);
      transition-property: opacity, transform;
      -webkit-transition-property: opacity, transform;
      transition-duration: .4s;
      -webkit-transition-duration: .4s;
      // https://stackoverflow.com/questions/21767037/css-transitions-not-working-in-safari
      transform: translate(0, -40px);
      -webkit-transform: translate(0, -40px);
      .o(0);
      // 그림자 효과
      &:after {.abs;
        transform: matrix(1, 0, 0, -1, 0, 0);.l(0);.t(50);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .text-white[data-in-scroll='true'] {.o(1);
      transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
    }
    // 그림자 효과 텍스트
    .text-white.first {
      &:after { content: '롤이';}
    }
    .text-white.second {
      &:after { content: '느껴진다면?';}
    }
    .carousel-box {.ml(56);.mr(56);.wh(337, 67);.rel;
      [lottie] {.abs;.lt(0, 0);}
    }
  }
  // mobile
  @media (max-width: 1000px) {
    .h(435);
    .title-area {flex-wrap: wrap;.flex-jc(center)}
    .lottie-emoji-area {.w(100%);}
    .lottie-emoji {.wh(206, 206);.m(auto);}
    .title-area {
      .text-white {.fs(24);.lh(32);.h(32);
        &:after {.t(29)}
      }
      .carousel-box {.wh(120, 90);.ml(12);.mr(12);.t(30);
        .carousel-wrap {.t(15)}
      }
    }
  }
}
</style>
