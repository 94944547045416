<template>
  <section landing-main-section>
    <div class="parallax-clip">
      <div class="fixed-parallax">
        <img class="parallax-bg-left" src="/img/coaching/bg-symbol-white-left.svg" />
        <img class="parallax-bg-right" src="/img/coaching/bg-symbol-white-right.svg" />
      </div>
    </div>
    <div class="left-holder">
      <span class="desc-sub">1:1 맞춤 온라인 게임 강의</span>
      <p class="desc">게임코치온라인</p>
      <h2 class="title">
        <scroll-observer tag="span" class="fade" :delay-enter="500">
          <em>방법</em>
        </scroll-observer>
        <span>을 바꿔야,</span>
        <br />
        <scroll-observer tag="span" class="fade" :delay-enter="1000">
          <em>티어</em>
        </scroll-observer>
        <span>가 바뀐다.</span>
      </h2>
      <BasicButton class="btn" theme="red" @click="linkHandler">지금 바로 올리기!</BasicButton>
    </div>
    <scroll-observer @enter-scroll="onLottieScroll" class="right-holder">
      <Lottie autoplay class="lottie" src="/lottie/tier-graph.json" ref="lottie" />
      <img src="/img/coaching/tier-list.png" />
    </scroll-observer>
  </section>
</template>

<script>
import Lottie from '@shared/components/general/Lottie.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import ScrollObserver from '@/views/components/coaching/ScrollObserver.vue';

export default {
  name: 'LandingMainSection',
  components: {
    BasicButton, Lottie, ScrollObserver,
  },
  methods: {
    linkHandler() {
      this.$gtag.event('click_user_landing_cta_main_section', {
        'event_category': 'user_landing',
        'event_label': 'click_cta_main_section',
      });
      this.$router?.push({ name: 'CoachMatching' });
    },
    onLottieScroll() {
      this.$refs.lottie.play();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[landing-main-section] { .w(100%); .h(700); .noto; .rel; overflow: hidden; .flex; .justify-center; .items-center;
  .parallax-clip {.wh(100%, 100%);.abs;.lt(0, 0); clip: rect(0, auto, auto, 0);}
  .parallax-bg-left {.abs;left: -336px;top: 0;.h(800);transform: scale(.8);}
  .parallax-bg-right {.abs;right: -430px;bottom: 271px;.h(800);transform: scale(.5)}
  //.fixed-parallax {position: fixed;.lt(50%, 50%);transform: translate(-50%, -50%);}
  .fixed-parallax {position: fixed;.wh(100%, 100%);left: 0;top: 0;bottom: 0;right: 0;}

  .fade {
    transition-property: opacity, transform;
    -webkit-transition-property: opacity, transform;
    transition-duration: .8s;
    -webkit-transition-duration: .8s;
    display: inline-block;
  }
  .fade:not([data-in-scroll='true']) {
    opacity: 0;
    transform: translate(0, 15px);
    -webkit-transform: translate(0, 15px);
  }

  /* CSS specific to iOS devices */
  @supports (-webkit-overflow-scrolling: touch) {
    .parallax-clip {
      clip: rect(auto, auto, auto, auto);
    }
    .fixed-parallax {
      position: fixed;
      background-attachment: unset;
      -webkit-transform: translateZ(0); /* necessary for iOS! */
    }
  }
  .left-holder { .mr(140); .z(1);
    .desc-sub {.fs(14); .lh(24); .c(#444); }
    .desc {.c(#191919); .fs(24); .lh(36); .mb(16); .bold; .c(#191919)}
    .title {.fs(45); .lh(64); .bold; .mb(48);
      em {.c(#d40404);}
    }
    .btn {.h(48); border: unset; .br(8);}
  }
  .right-holder { .w(433); .h(308); .rel; .z(1);
    .lottie {.w(520); .h(520); .t(-106); .l(-103);}
    > img {.abs; .w(100); .h(302); .r(0); .t(3);}
  }
  @media (@ds-down) {
    .parallax-bg-right { .hide}
  }
  @media (@tp-down) {
    .parallax-bg-left {.hide}
    .h(622);
    flex-direction: column;
    //.bg-wrap-inner > img {.hide;}
    .left-holder {.mr(0); .flex; flex-direction: column; .items-center;
      .desc-sub {.fs(12); .lh(18); }
      .desc {.fs(20); .lh(32); .mb(24);}
      .title {.fs(32); .lh(42); .mb(24); }
      .btn {.h(40);}
    }
    .right-holder { .w(288); .h(220); .mt(60);
      .lottie {.t(-63); .l(-76); .w(353); .h(353);}
      > img {.w(70); .h(220); .t(0);}
    }
  }
}

</style>
