<template>
  <component :is="tag" :data-in-scroll="inScroll">
    <transition v-if="showOnlyOnScroll" v-bind="transitions">
      <slot v-if="inScroll"></slot>
    </transition>
    <slot :in-scroll="inScroll" v-else></slot>
  </component>
</template>

<script>
// https://github.com/BiYuqi/vue-intersection-observer/blob/master/src/components/observer/Observer.vue
export default {
  name: 'observer',
  data() {
    return {
      /** @type{IntersectionObserver | null} */
      observer: null,
      inScroll: false,
    };
  },
  props: {
    tag: {
      type: [Object, String],
      default: 'div'
    },
    root: {
      type: [Object],
      default: null
    },
    rootMargin: {
      type: [String, Number],
      default: '0px'
    },
    threshold: {
      type: [Array, Number],
      default: () => [0.0, 0.5, 1]
    },
    delayEnter: {
      type: Number,
      default: 0
    },
    // 스크롤 되었을 때애만 슬롯 표기
    showOnlyOnScroll: {
      type: Boolean,
      default: false,
    },
    transitions: {
      type: [Object, undefined],
      // https://v2.vuejs.org/v2/guide/transitions.html#JavaScript-Hooks
      default: () => ({ enter: 'enter', leave: 'leave' })
    }
  },
  methods: {
    // onObserve -> onEnter()
    onObserve(entry) {
      // const isIntersecting = entry?.isIntersecting;
      const isIntersecting = entry?.intersectionRatio > 0;
      if (isIntersecting) this.onEnter();
      else this.onLeave();
      this.$emit('observe', entry);
    },
    onEnter() {
      this.$observeTimerId = setTimeout(() => {
        this.inScroll = true;
        this.$emit('enter-scroll-finish')
      }, this.delayEnter)
      this.$emit('enter-scroll');
    },
    onLeave() {
      this.inScroll = false;
      if (this.$observeTimerId) clearTimeout(this.$observeTimerId)
      this.$emit('leave-scroll');
    },
  },
  mounted() {
    const options = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold
    };
    this.observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      this.onObserve(entry);
    }, options);
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    if (this.$observeTimerId) {
      clearTimeout(this.$observeTimerId)
      this.$observeTimerId = undefined;
    }
    if (this.observer) {
      if (this.observer?.unobserve) this.observer.unobserve(this.$el)
      this.observer = null;
    }
  }
};
</script>