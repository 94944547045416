<template>
  <div free-slider ref="slider" v-drag-scroll @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="content-wrap" v-if="auto">
      <!-- duplicated slot is necessary for infinite scroll -->
      <slot v-for="(_,index) in Array.from({length:2})" :index="index">
      </slot>
    </div>
    <div class="content-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import dragScroll from '@/directive/dragScroll';

export default {
  data: () => ({
    scrollMax: 0,
    startTime: -1,
    isAnimating: false,
  }),
  props: {
    auto: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 30 * 1000,
    },
  },
  directives: {
    dragScroll,
  },
  methods: {
    checkScroll() {
      return typeof this.$refs.slider?.scrollLeft !== 'undefined';
    },
    getScrollMax() {
      if (!this.checkScroll() || !this.$refs.slider) return 0;
      return this.$refs.slider.scrollWidth - this.$refs.slider.clientWidth;
    },
    calculateFrame() {
      if (!this.$refs.slider) return;

      this.$refs.slider.scrollLeft += 1.5;
      // this.$refs.slider.scrollLeft += 5;
      if (this.scrollMax <= this.$refs.slider.scrollLeft) {
        this.$refs.slider.scrollLeft = this.scrollMax / 2;
      }
      this.onAnimate();
    },
    onAnimate() {
      if (!this.isAnimating) return;
      window.requestAnimationFrame(this.calculateFrame.bind(this));
    },
    startRoll() {
      this.onResize();
      this.isAnimating = true;
      this.onAnimate();
    },
    onMouseEnter() {
      this.isAnimating = false;
    },
    onMouseLeave() {
      this.startRoll();
    },
    onResize() {
      this.scrollMax = this.getScrollMax();
    },
  },
  mounted() {
    if (this.auto) {
      this.$nextTick(() => {
        this.startRoll();
        window.addEventListener('resize', this.onResize.bind(this));
      });
    }
  },
  beforeDestroy() {
    this.isAnimating = false;
    window.removeEventListener('resize', this.onResize.bind(this));
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[free-slider] {.w(100%);.h(430);overflow: auto; overflow-x: hidden;overflow-y: visible;.flex;flex-direction: row;white-space: nowrap;
  .content-wrap {.flex;flex-direction: row;white-space: nowrap;}
  cursor: grab;
  &[data-dragging='true'] {
    cursor: grabbing;
  }
}
</style>
