// 코칭 랜딩페이지에 사용할 임시 자료
// 최대한 LessonReview와 유사하게 구성하고, 기존에 없는 것만 새로운 키값으로 추가
export default [
  {
    user: { nickname: '******oo', userId: 'a' },
    coachNickname: 'Avalon',
    description: '‘역시 챌린저는 다르구나...’ 하고 감탄 했습니다. 이기기 위한 플레이, 피해야 할 플레이 정확히 짚어 주셔서 놀라웠습니다. 확실하게 방향성을 제시해 주시고 중간 중간 꿀팁까지...너무나도 명품 코칭이었습니다.',
    headline: '이래서 돈주고 피드백 받는구나 싶네요',
    lessonReviewId: '1'
  },
  {
    user: { nickname: '*****vg', userId: 'b' },
    coachNickname: 'BLUE',
    description: '플레이 방향성을 다시 한번 생각하게 되는 코칭이었습니다. 솔랭 특성 상 서폿의 성향이 다르므로 그에 따른 플레이 수정이 필요하다는 걸 알게 됐습니다.',
    headline: '신기하게 매판 할 때마다 판단력이 바로 좋아지는게 느껴집니다',
    lessonReviewId: '2'
  },
  {
    user: { nickname: '***_9', userId: 'c' },
    coachNickname: 'Mareritt',
    headline: '롤하면서 쓴 수천일의 시간중에 최고로 유익하게 잘 쓴 것 같아요!',
    description: '온라인으로 롤을 배우는것이 어떨지 반신반의한 상태로 강의를 신청했는데 미드라인에 아예 기초지식도 없던 저에게 라인전부터 챔프 이용까지 자세하고 친절하게 알려 주셔서 너무너무 감사했습니다. 재수강 의사 2000%입니다!',
    lessonReviewId: '3',
  },
  {
    user: { nickname: '****f5', userId: 'd' },
    coachNickname: 'GAP',
    headline: '5티어 상승, 승률 11%상승 듣지 말아야 할 이유가 없음',
    description: '어떻게 하면 분당 cs를 챙길 수 있는지에 대한 조언 등 어떠한 강의에서도 들을 수 없는 정보들을 얻어갔습니다. 들으면 들을수록 실력이 늘어간다는 게 몸소 느껴졌고 정말 어디에서도 배울 수 없는 강의라 생각합니다.',
    lessonReviewId: '4'
  },
  {
    user: { nickname: '****au', userId: 'e' },
    coachNickname: 'GAP',
    description: '만날 생각 없이 게임만 해서 티어가 그대로여서 신청했습니다. 원딜보고 그냥 피지컬 올려라 이런 말씀 절대 안하시고 상황별로 제가 못 하는 것 중에 가장 먼저 보완해야 될 것부터 차근차근 알려 주십니다.',
    headline: '팀 탓만 할게 아니라 내가 잘하면 올라갈 수 있다고 생각이 바뀌었습니다',
    lessonReviewId: '5',
  },
  {
    user: { nickname: '*****le', userId: 'f' },
    coachNickname: 'blossom',
    headline: '만년 실버・골드 유저에서 다이아4까지 수직상승',
    description: '해야할 것 하지 말아야 할 것 확실하게 알려 주시니 판단력 기르는데 확실히 도움 많이 되는 것 같습니다. 뭐 사실 다른 이유가 필요한가요? 프로씬에서 활동한 T1정글러 출신이신데 경력만 해도 이미 검증된 분이시죠.',
    lessonReviewId: '6',
  },
  {
    user: { nickname: '*****do', userId: 'g' },
    coachNickname: 'NoRoo',
    headline: '특히 저티어분들이 들으시면 정말 도움이 많이 될 거라는 생각이 듭니다',
    description: '아무것도 모르는 상태에서 제일 기초가 되는 부분 부터 친절하게 가르쳐 주시고 강의 분위기를 밝게 이어나가 주셔서 굉장히 편하게 했습니다. 긴장한 탓에 이것저것 실수가 많았어도 주눅들지 않게 하나하나 친절하게 알려 주시는 점 너무 감사했습니다.',
    lessonReviewId: '7'
  },
  {
    user: { nickname: '**nc', userId: 'h' },
    coachNickname: 'GAP',
    headline: '수업 듣고 정말 많이 늘어서 주변 여러 사람에게 소개하고 있습니다',
    description: '코치님 덕분에 챔프 폭도 점점 늘려가고 그저 벌레에서 어엿한 한 \'사람\'의 원딜이 되어가고 있습니다. 부족한 점을 너무 잘 알려 주시고 롤이 돌아가는 구조를 파악 할 수 있게 해주셔서 안대를 벗은 느낌입니다.',
    lessonReviewId: '8',
  },
  {
    user: { nickname: '***bk', userId: 'i' },
    coachNickname: 'beom9',
    headline: '말파 하면서 처음으로 팀원들한테 쩔었다는 소리 들어봤네요 ㅎㅎㅎ',
    description: '온라인 코칭이라는게 어색했는데 너무 친절하게 잘 가르쳐 주셔서 감사했습니다. 초중반 라인전이 이렇게 중요한지 이제 알았습니다. 맘 같아선 마스터도 갈수 있을 것 같아요!',
    lessonReviewId: '9'
  },
  {
    user: { nickname: '****크크', userId: 'j' },
    coachNickname: 'Beginning',
    headline: '거짓말 하나도 안하고 공부를 이렇게 가르쳐 줬으면 서울대 입구까지는 갔습니다',
    description: '리플레이 보면서 잘못된 점 잘한 점 일일이 짚어 주셔서 너무 만족스러운 강의였습니다. 별점5개로 모자라네요, 티어벽이 느껴질 때 또 찾아뵙겠습니다!',
    lessonReviewId: '10'
  }
];